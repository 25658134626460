import { Component } from "react";
import { Button, Form, Pagination } from "react-bootstrap";
import BarComponent from '../userbar/BarComponent'
import '../../styles/simcards.css'
import addIcon from '../../img/add-icon.svg'
import editIcon from '../../img/edit_icon.png'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import searchIcon from '../../img/search-icon.svg'
import DeleteUserModal from "./DeleteUserModal";
import API from '../api/api'


const itemsPerPage = 4

class UsersView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            currentPage: 1,
            totalPages: 3
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.getUsers = this.getUsers.bind(this)
    }

    componentDidMount() {
        this.getUsers(1)
    }

    getUsers(page) {
        this.setState({ loading: true })
        API.get(`/user/all?page=${page}&limit=${itemsPerPage}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(({ data }) => {
                this.setState({
                    users: data.content,
                    totalPages: data.totalPages
                })
            })
            .catch(() => this.setState({ error: true }))
            .finally(() => this.setState({ loading: false }))
    }

    handleSubmit(e) {
        e.preventDefault()
        const values = Object.fromEntries(new FormData(e.target))

        // si el valor de un campo es un string vacio, lo eliminamos del objeto
        for (const fil in values) {
            if (!values[fil].trim()) delete values[fil]
        }

        const filterValues = Object.entries(values).join('&').replaceAll(',', '=')

        API.get(`/user/all?page=1&limit=${itemsPerPage}&${filterValues}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(({ data }) => {
                this.setState({
                    users: data.content,
                    totalPages: data.totalPages,
                    currentPage: 1
                })
            })
            .catch(() => this.setState({ error: true }))
            .finally(() => this.setState({ loading: false }))
    }

    handlePageChange(page) {
        if (page === this.state.currentPage || page < 1 || page > this.state.totalPages) return
        this.setState({ currentPage: page })
        this.getUsers(page)
    }

    render() {
        return (
            <>
                <BarComponent propsBar={{ title: 'Usuarios', isMainPage: false }} />
                <section className="container">

                    <header className="d-flex justify-content-center flex-column mt-5">

                        <Form onSubmit={this.handleSubmit} className="d-flex flex-column">
                            <div className="position-relative d-flex">
                                <Form.Control type="text" name="email" placeholder="Correo" className="pl-5" />
                                <div style={{ top: -2 }} className="position-absolute translate-middle-y p-2 pl-3">
                                    <img src={searchIcon} width={19} alt="search" />
                                </div>
                                <Button type="submit" className="ml-2 px-4">Buscar</Button>
                            </div>
                            
                            <div className="d-flex select-container align-items-center align-self-center mt-4">
                                <p className="m-0 mr-2">Estado</p>
                                <select name="active" className="mr-2">
                                    <option value="">Todos</option>
                                    <option value="true">Activo</option>
                                    <option value="false">No activo</option>
                                </select>
                            </div>
                        </Form>

                        <hr width='90%' />

                        <Link to="/users/add" className="align-self-end">
                            <img src={addIcon}
                                alt="Agregar usuario"
                                className="add-icon"
                                width="52"
                            />
                        </Link>
                    </header>

                    <div className="sims-container text-center mb-5 my-3">
                        {this.state.users.length === 0
                            ? <h2>No hay resultados con los filtros seleccionados</h2>
                            : (
                                this.state.users.map(user => (
                                    <div key={user.email} className="border card rounded shadow-sm bg-light mb-3 align-items-center p-2">
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">Correo</p>
                                                <h5 className="mb-0">{user.email}</h5>
                                            </div>
                                        </div>
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">Nombre</p>
                                                <h5 className="mb-0">{user.firstName} {user.lastName}</h5>
                                            </div>
                                        </div>

                                        <div style={{ flex: '1', minWidth: '0' }} className="text-center p-2 buttons-card">
                                            <img
                                                src={editIcon}
                                                alt="Editar"
                                                width="25"
                                                onClick={() => this.props.history.push(`/users/edit/${user.email}`)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <DeleteUserModal getUsers={() => this.getUsers(this.state.currentPage)} user={user} />
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>

                    <Pagination className="d-flex justify-content-center">
                        <Pagination.Prev onClick={() => this.handlePageChange(this.state.currentPage - 1)} />
                        {Array.from({ length: this.state.totalPages }, (_, i) => (
                            <Pagination.Item key={i} active={i + 1 === this.state.currentPage} onClick={() => this.handlePageChange(i + 1)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => this.handlePageChange(this.state.currentPage + 1)} />
                    </Pagination>
                </section>
            </>
        )
    }
}

export default UsersView