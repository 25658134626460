import { Component } from "react";
import { Button, Form, Pagination, Spinner } from "react-bootstrap";
import BarComponent from '../userbar/BarComponent'
import '../../styles/simcards.css'
import addIcon from '../../img/add-icon.svg'
import editIcon from '../../img/edit_icon.png'
import API from "../api/api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DeleteSimModal from "./DeleteSimModal";
import searchIcon from '../../img/search-icon.svg'
import { operators } from "../utils/Constants";


const itemsPerPage = 4

class Simcards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sims: [],
            currentPage: 1,
            loading: false,
            totalPages: 0,
            error: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getSimCards = this.getSimCards.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    componentDidMount() {
        this.getSimCards(1)
    }

    getSimCards(page) {
        this.setState({ loading: true })
        API.get(`/simcards/all?page=${page}&limit=${itemsPerPage}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(({ data }) => {
                const simsParsedDate = data.content.map(sim => ({ ...sim, activationDate: sim.activationDate.split('T')[0] }))
                this.setState({
                    sims: simsParsedDate,
                    totalPages: data.totalPages
                })
            })
            .catch(() => this.setState({ error: true }))
            .finally(() => this.setState({ loading: false }))
    }

    handlePageChange(page) {
        if (page === this.state.currentPage || page < 1 || page > this.state.totalPages) return
        this.setState({ currentPage: page })
        this.getSimCards(page)
    }

    handleSubmit(e) {
        e.preventDefault()
        const values = Object.fromEntries(new FormData(e.target))

        // si el valor de un campo es un string vacio, lo eliminamos del objeto
        for (const fil in values) {
            if (!values[fil].trim()) delete values[fil]
        }

        const filterValues = Object.entries(values).join('&').replaceAll(',', '=')

        API.get(`/simcards/all?page=1&limit=${itemsPerPage}&${filterValues}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(({ data }) => {
                const simsParsedDate = data.content.map(sim => ({ ...sim, activationDate: sim.activationDate.split('T')[0] }))
                this.setState({
                    sims: simsParsedDate,
                    totalPages: data.totalPages,
                    currentPage: 1
                })
            })
            .catch(() => this.setState({ error: true }))
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <>
                <BarComponent propsBar={{ title: 'SimCards', isMainPage: false }} />
                <section className="container">

                    <header className="d-flex justify-content-center flex-column mt-5">

                        <Form onSubmit={this.handleSubmit} className="d-flex flex-column">
                            <div className="position-relative d-flex">
                                <Form.Control type="number" name="iccid" placeholder="ICCID" className="pl-5" />
                                <div style={{ top: -2 }} className="position-absolute translate-middle-y p-2 pl-3">
                                    <img src={searchIcon} width={19} alt="search" />
                                </div>

                                <Button type="submit" className="ml-2 px-4">Buscar</Button>
                            </div>

                            <div className="d-flex select-container align-items-center align-self-center mt-4">

                                <p className="m-0 mr-2">Estado</p>
                                <select name="status" className="mr-2">
                                    <option value="">Todos</option>
                                    <option value="activo">Activo</option>
                                    <option value="dañado">Dañado</option>
                                    <option value="desactivado">Desactivado</option>
                                </select>

                                <p className="m-0 mr-2 ml-4">Operador</p>
                                <select name="operator">
                                    <option value="">Todos</option>
                                    {operators.map(operator => (
                                        <option key={operator.value} value={operator.value}>{operator.label}</option>
                                    ))}
                                </select>
                            </div>
                        </Form>

                        <hr width='90%' />

                        <Link to="/simcards/add" className="align-self-end">
                            <img src={addIcon}
                                alt="Agregar simcard"
                                className="add-icon"
                                width="52"
                            />
                        </Link>
                    </header>

                    <div className="sims-container text-center mb-5 my-3">
                        {this.state.loading && (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only" />
                                </Spinner>
                            </div>
                        )}
                        {this.state.error && <h2>Error al cargar las SimCards</h2>}
                        {this.state.sims.length === 0 && !this.state.loading && !this.state.error
                            ? <h2>No hay resultados con los filtros seleccionados</h2>
                            : (
                                this.state.sims.map(sim => (
                                    <div key={sim.id} className="border card rounded shadow-sm bg-light mb-3 align-items-center p-2">
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">ID SimCard</p>
                                                <h5 className="mb-0">{sim.id}</h5>
                                            </div>
                                        </div>
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">ICCID</p>
                                                <h5 className="mb-0" style={{ fontSize: '1.1em' }}>{sim.iccid}</h5>
                                            </div>
                                        </div>
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">Activacion</p>
                                                <h5 className="mb-0">{sim.activationDate}</h5>
                                            </div>
                                        </div>
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">Estado</p>
                                                <h5 className="mb-0 text-capitalize">{sim.status}</h5>
                                            </div>
                                        </div>
                                        <div style={{ flex: '1', minWidth: '0' }} className="text-center p-2 buttons-card">
                                            <img
                                                src={editIcon}
                                                alt="Editar"
                                                width="25"
                                                onClick={() => this.props.history.push(`/simcards/edit/${sim.id}`)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <DeleteSimModal getSims={() => this.getSimCards(this.state.currentPage)} simId={sim.id} />
                                        </div>
                                    </div>
                                ))
                            )

                        }
                    </div>

                    <Pagination className="d-flex justify-content-center">
                        <Pagination.Prev onClick={() => this.handlePageChange(this.state.currentPage - 1)} />
                        {Array.from({ length: this.state.totalPages }, (_, i) => (
                            <Pagination.Item key={i} active={i + 1 === this.state.currentPage} onClick={() => this.handlePageChange(i + 1)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => this.handlePageChange(this.state.currentPage + 1)} />
                    </Pagination>
                </section>
            </>
        )
    }
}

export default Simcards