import React, { Component } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { withRouter } from 'react-router-dom';
import format from 'date-fns/format';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import API from './../api/api';
import DayPicker, { DateUtils } from 'react-day-picker';
import LoadingComponent from '../loading/LoadingComponent';

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CsvDownloadButton from 'react-json-to-csv';
import {
    operationForChangeVbValue,
    operationForChangeCeValue,
    operationForChangePhValue
} from '../utils/AdjustData';

import {
    MONTHS,
    WEEKDAYS_LONG,
    WEEKDAYS_SHORT,
    graphColors,
} from '../utils/GraphUtils';

import 'react-rangeslider/lib/index.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import 'react-day-picker/lib/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/graphs.css';

import RangeSlider from 'react-bootstrap-range-slider';
import Form from 'react-bootstrap/Form';

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element) {
    mouseClickEvents.forEach((mouseEventType) =>
        element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
            })
        )
    );
}

const formatDate = (date) => {
    const pad = (num) => (num < 10 ? '0' + num : num); // Función para agregar ceros a la izquierda
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Los meses son 0-indexados
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const convertToLocalTime = (data) => {//se usa solo para descargar datos
    return data.map(item => {
        const localDate = new Date(item.x); // Suponiendo que item.x es la fecha en formato ISO
        return {
            ...item,
            x: formatDate(localDate), // Convierte a hora local y aplica el formato deseado
        };
    });
};

class VariableGraph extends Component {
    constructor(props) {
        super(props);
        const graphData = this.props.values.graphData;

        this.state = {
            sensorId: graphData.sensorId,
            varId: graphData.varId,
            varName: graphData.varName,
            data: graphData.data,
            units: graphData.units,
            color: graphColors[graphData.data[0].id],
            range: {
                from: undefined,
                to: undefined,
            },
            oldRange: {
                from: undefined,
                to: undefined,
            },
            initialMaxThreshold: graphData.initialMaxThreshold,
            actualMaxThreshold: graphData.actualMaxThreshold,
            initialMinThreshold: graphData.initialMinThreshold,
            actualMinThreshold: graphData.actualMinThreshold,
            showDatePicker: false,
            noDataAvaliable: false,
            loaded: true,
            step: graphData.data.id === 'CE' ? 0.1 : 1,
        };

        this.selectDate = this.selectDate.bind(this);
        this.downloadCsvData = this.downloadCsvData.bind(this);
        this.handleShowModalThreshold =
            this.handleShowModalThreshold.bind(this);
        this.handleCloseModalThreshold =
            this.handleCloseModalThreshold.bind(this);
            this.handleClickReset = this.handleClickReset.bind(this)
    }

    handleClickReset() {
        this.setState({ range: { from: undefined, to: undefined } });
    }

    requestMeasures(startDate, endDate) {
        this.setState({ loaded: false });

        if (this.state.varId) {
            API.get('/sensors/measurements', {
                params: {
                    idSensor: this.state.sensorId,
                    idVariable: this.state.varId,
                    startDate: startDate,
                    endDate: endDate,
                    recursive: false,
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        var measures = res.data.measures || [];

                        if (measures.length > 0) {
                            if (this.state.varId === 'VB')
                                operationForChangeVbValue(measures);

                            if (this.state.varId === 'CE')
                                operationForChangeCeValue(measures, this.state.sensorId);
                            if (this.state.varId === 'PH')
                                operationForChangePhValue(measures, this.state.sensorId);
                            let elemento = {};
                            elemento.id = this.state.varId;
                            elemento.data = measures;
                            let completo = [];
                            completo.push(elemento);

                            this.setState({
                                data: completo,
                                varName: res.data.variableName,
                                units: res.data.variableUnit,
                                initialMaxThreshold:
                                    res.data.threshold.maxLimit,
                                actualMaxThreshold: res.data.threshold.maxValue,
                                initialMinThreshold:
                                    res.data.threshold.minLimit,
                                actualMinThreshold: res.data.threshold.minValue,
                                noDataAvaliable: false,
                            });
                        } else {
                            this.changeDatePickerVisibility();
                            this.setState({ noDataAvaliable: true });
                        }
                    } else {
                        // Handle error response
                        console.error('Error fetching data:', res);
                    }

                    this.setState({ loaded: true });
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem('user-jwt');
                        localStorage.removeItem('actual-sensor');
                        this.props.history.push('/');
                    } else {
                        // Handle other errors
                        // Set appropriate error state here
                    }
                });
        } else {
            this.props.history.push('/error');
        }
    }

    downloadCsvData(e) {
        let currentCsvButtonId = 'csvButton' + this.state.varId;
        var element = document.querySelector(
            'button[id=' + '"' + currentCsvButtonId + '"' + ']'
        );

        simulateMouseClick(element);
    }

    handleShowModalThreshold() {
        this.setState({ showModalThreshold: true });
    }

    handleCloseModalThreshold() {
        this.setState({ showModalThreshold: false });
    }

    handleChangeMaxTreshold = (value) => {
        const realValue = Number(value.target.value);
        if (realValue > this.state.actualMinThreshold)
            this.setState({
                actualMaxThreshold: realValue,
            });
    };

    handleChangeMinTreshold = (value) => {
        const realValue = Number(value.target.value);
        if (realValue < this.state.actualMaxThreshold)
            this.setState({
                actualMinThreshold: realValue,
            });
    };

    handleUpdateThreshold = (e) => {
        e.preventDefault();

        let threshold = {
            variableId: this.state.varId,//variableId: this.props.values.varId,
            sensorId: this.state.sensorId,//sensorId: this.props.values.sensorId,
            minValue: this.state.actualMinThreshold,
            maxValue: this.state.actualMaxThreshold,
            minLimit: this.state.initialMinThreshold,
            maxLimit: this.state.initialMaxThreshold,
        };

        API.post('/sensors/update-threshold', threshold, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt'),
            },
        })
            .then((res) => { })
            .catch((error) => {
                if (error.response.status === 401) {
                    localStorage.removeItem('user-jwt');
                    localStorage.removeItem('actual-sensor');
                    this.props.history.push('/');
                } else this.props.history.push('/error');
            });

        this.handleCloseModalThreshold();
    };

    handleDayClick = (day) => {
        const oldRange = this.state.range;
        const range = DateUtils.addDayToRange(day, this.state.range);
        this.setState({ range, oldRange });
    };

    changeDatePickerVisibility = () => {
        this.setState({
            showDatePicker: !this.state.showDatePicker,
        });
    };

    selectDate(e) {
        if (this.state.range.from !== undefined) {
            e.preventDefault();

            if (
                this.state.range.from >= this.state.oldRange.from &&
                this.state.range.to <= this.state.oldRange.to
            ) {
                let newMeasures = [];
                let oldMeasures = this.state.data[0].data;

                const startDate = format(
                    this.state.range.from,
                    "yyyy-MM-dd'T'00:00:00.000'-05:00'"
                );
                const endDate = format(
                    this.state.range.to,
                    "yyyy-MM-dd'T'23:59:59.000'-05:00'"
                );

                oldMeasures.forEach((measure) => {
                    if (measure.x >= startDate && measure.x <= endDate) {
                        newMeasures.push(measure);
                    }
                });

                let elemento = {};
                elemento.id = this.state.varId;
                elemento.data = newMeasures;
                let completo = [];
                completo.push(elemento);

                this.setState({ data: completo });
            } else {
                const startDate = format(
                    this.state.range.from,
                    "yyyy-MM-dd'T'00:00:00.000'-05:00'"
                );
                const endDate = format(
                    this.state.range.to,
                    "yyyy-MM-dd'T'23:59:59.000'-05:00'"
                );

                this.requestMeasures(startDate, endDate);
            }

            this.changeDatePickerVisibility();
        }
    }

    render() {
        const { from, to } = this.state.range;
        const modifiers = { start: from, end: to };

        const loadingProps = {
            message: '',
            type: 'success',
        };

        return (
            <Container
                id={'_graph' + this.props.values.varId}
                className="_graph"
            >
                <Row id="ContainerTitleGraph">
                    <h3 id="TitleGraph">{this.state.varName}</h3>
                    <CsvDownloadButton
                        id={'csvButton' + this.state.varId}
                        style={{ visibility: 'hidden' }}
                        data={
                            this.state.data.length > 0
                                ? convertToLocalTime(this.state.data[0].data) // Convierte las fechas a hora local
                                : []
                        }
                        filename={this.state.varName}
                        headers={[this.state.varName, 'Hora']}
                    />
                    <Col id="ContainerButtonsTitle">
                        <Button id="ButtonCSV" onClick={this.downloadCsvData}>
                            {' '}
                        </Button>
                        <Button
                            id="ButtonConfImage"
                            onClick={this.handleShowModalThreshold}
                        >
                            {' '}
                        </Button>
                        <Button
                            id="ButtonFilterImage"
                            onClick={this.changeDatePickerVisibility}
                        >
                            {' '}
                        </Button>
                    </Col>
                    <Modal
                        show={this.state.showDatePicker}
                        onHide={this.changeDatePickerVisibility}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Selecciona un rango de fechas para hacer el
                                filtro
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body id="ModalGraph">
                            <p id="alertDataNoAvaliable" style={{ margin: 0, padding: 0 }}>
                                {this.state.noDataAvaliable &&
                                    'No hay datos disponibles en las fechas estipuladas' +
                                    ', selecciona una nueva fecha'}
                            </p>
                            <p id="selector-date-id">
                                {!this.state.range.from &&
                                    !this.state.range.to &&
                                    'Selecciona la primera fecha.'}
                                {this.state.range.from &&
                                    !this.state.range.to &&
                                    'Selecciona la fecha final.'}
                                {this.state.range.from &&
                                    this.state.range.to &&
                                    `Seleccionaste desde ${this.state.range.from.toLocaleDateString()} hasta
                                        ${this.state.range.to.toLocaleDateString()}`}{' '}
                                {this.state.range.from &&
                                    this.state.range.to && (
                                        <Button
                                            id="ButtonResetDate"
                                            onClick={this.handleClickReset}
                                        >
                                            Reiniciar
                                        </Button>
                                    )}
                            </p>
                            <DayPicker
                                className="Selectable"
                                locale="es"
                                months={MONTHS}
                                weekdaysLong={WEEKDAYS_LONG}
                                weekdaysShort={WEEKDAYS_SHORT}
                                numberOfMonths={1}
                                selectedDays={[from, { from, to }]}
                                modifiers={modifiers}
                                onDayClick={this.handleDayClick}
                            />

                            <Button
                                id="ButtonOkCalendar"
                                className="ButtonOkCalendarStyle"
                                type="button"
                                disabled={!this.state.range.to}
                                onClick={this.selectDate}
                            >
                                Aplicar
                            </Button>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.showModalThreshold}
                        onHide={this.handleCloseModalThreshold}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Modifica tu nuevo umbral máximo y mínimo
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Valor máximo</h4>
                            <h6>
                                {' '}
                                Actualmente en:{' '}
                                {this.state.actualMaxThreshold +
                                    ' ' +
                                    this.state.units}
                            </h6>
                            <RangeSlider
                                step={this.state.step}
                                value={this.state.actualMaxThreshold}
                                onChange={this.handleChangeMaxTreshold}
                                min={this.state.initialMinThreshold}
                                max={this.state.initialMaxThreshold}
                            />
                            <br />
                            <h6> Nuevo valor máximo: </h6>
                            <Form.Control
                                readOnly
                                type="text"
                                placeholder="Valor máximo"
                                value={
                                    this.state.actualMaxThreshold +
                                    ' ' +
                                    this.state.units
                                }
                            />
                            <hr />
                            <h4>Valor mínimo</h4>
                            <h6>
                                {' '}
                                Actualmente en:{' '}
                                {this.state.actualMinThreshold +
                                    ' ' +
                                    this.state.units}
                            </h6>
                            <RangeSlider
                                value={this.state.actualMinThreshold}
                                onChange={this.handleChangeMinTreshold}
                                min={this.state.initialMinThreshold}
                                max={this.state.initialMaxThreshold}
                            />
                            <br />
                            <h6> Nuevo valor mínimo: </h6>
                            <Form.Control
                                readOnly
                                type="text"
                                placeholder="Valor mínimo"
                                value={
                                    this.state.actualMinThreshold +
                                    ' ' +
                                    this.state.units
                                }
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                id="cancelThresholdButton"
                                variant="secondary"
                                onClick={this.handleCloseModalThreshold}
                            >
                                Cancelar
                            </Button>
                            <Button
                                id="saveThresholdButton"
                                variant="primary"
                                onClick={this.handleUpdateThreshold}
                            >
                                Guardar cambios
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Row>

                <Row id="GraphRow">
                    {!this.state.loaded && (
                        <div>
                            <LoadingComponent data={loadingProps} />
                        </div>
                    )}
                    {this.state.loaded && (
                        <ResponsiveLine
                            colors={this.state.color}
                            enableArea={true}
                            areaBaselineValue={0}
                            areaOpacity={0.04}
                            lineWidth={3}
                            margin={{
                                top: 40,
                                right: 20,
                                bottom: 70,
                                left: 80,
                            }}
                            data={this.state.data}
                            animate={false}
                            enablePoints={false}
                            curve="monotoneX"
                            xScale={{
                                type: 'time',
                                format: '%Y-%m-%dT%H:%M:%S.%L%Z', //YYYY-MM-DD HH:mm:ss
                                precision: 'second',
                                useUTC: true,
                            }}
                            xFormat="time:%Y-%m-%d-%H:%M:%S"
                            yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                reverse: false,
                            }}
                            yFormat=",.2f"
                            axisLeft={{
                                orient: 'left',
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: this.state.units,

                                legendOffset: -50,
                                legendPosition: 'middle',
                            }}
                            axisBottom={{
                                orient: 'bottom',
                                tickSize: 6,
                                tickPadding: 2,
                                tickRotation: 30,
                                legend: 'Fecha',
                                legendOffset: 60,
                                legendPadding: 50,
                                legendPosition: 'middle',
                                format:
                                    this.state.data.length > 0 &&
                                        this.state.data[0].data.length > 800
                                        ? '%b %d'
                                        : '%b %d - %H:%M',
                                tickValues:
                                    this.state.data.length > 0 &&
                                        this.state.data[0].data.length > 800
                                        ? 'every 1 day'
                                        : 'every 2 hours',
                            }}
                            enableSlices={'x'}
                            sliceTooltip={({ slice }) => (
                                <div>
                                    {slice.points.map((point, index) => (
                                        <div key={index} id="tooltipSlice">
                                            Fecha: {point.data.xFormatted}{' '}
                                            <br />
                                            Valor: {point.data.yFormatted}{' '}
                                            {this.state.units}
                                        </div>
                                    ))}
                                </div>
                            )}
                            useMesh={true}
                        />
                    )}
                </Row>
            </Container>
        );
    }
}

export default withRouter(VariableGraph);
