import { Component } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import BarComponent from '../userbar/BarComponent'
import { objectHasEmptyValues } from '../utils/FormValidations'
import addIcon from '../../img/add-icon.svg'
import '../../styles/sensorsad.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { VARIABLES } from '../utils/Constants'
import API from '../api/api'

const initialSensor = {
    id: '',
    name: '',
    active: true,
    checked: false,
    startOperation: '',
    sendMail: true,
    description: '',
    sim: {
        id: '',
        iccid: ''
    }
}

class SensorsAddView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sensor: initialSensor,
            threshold: [],
            sims: [],
            soil_types: [],
            soilSensor: [{
                soilIdSensor: '',
                soilNumber: 1,
            }]
        }
        this.getDataTimeout = null
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleThresholdValue = this.handleThresholdValue.bind(this)
        this.setSimValue = this.setSimValue.bind(this)
        this.setVariableToShow = this.setVariableToShow.bind(this)
        this.addSonda = this.addSonda.bind(this)
        this.getSimsAutocomplete = this.getSimsAutocomplete.bind(this)
        this.handleSoilChange = this.handleSoilChange.bind(this)
        this.handleSondaDelete = this.handleSondaDelete.bind(this)
    }

    componentDidMount() {
        API.get('soilsensors/all',{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(res => {
                this.setState({
                    soil_types: res.data,
                })
            })
            .catch(err => console.error(err))
    }

    handleSubmit(e) {
        e.preventDefault()

        if (objectHasEmptyValues(this.state.sensor)) return alert('Todos los campos son requeridos')

        const formatVariables = this.state.threshold.map(va => {
            const { soilIdSensor, ...restOfThreshold } = va
            if (soilIdSensor > 1) {
                return {
                    ...restOfThreshold,
                    variableId: va.variableId + soilIdSensor
                }
            }
            return restOfThreshold
        })

        API.post('sensors/create', {
            sensor: this.state.sensor,
            threshold: formatVariables,
            soilSensor: this.state.soilSensor
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
            }
        })
        .then(() => alert('Sensor agregado correctamente'))
        .catch(err => {
            console.log(err)
            alert('Error al agregar el sensor')
        })
    }

    handleChange(e) {
        const { name, value } = e.target
        const isBooleanValue = name === 'sendMail' || name === 'active'

        if (name === 'sim') {
            this.setState({
                sensor: {
                    ...this.state.sensor,
                    sim: {
                        iccid: value,
                        id: this.state.sims.find(sim => sim.iccid === value)?.id
                    }
                }
            })

            clearTimeout(this.getDataTimeout);
            this.getDataTimeout = setTimeout(this.getSimsAutocomplete, 1000);
            return
        }

        this.setState({
            sensor: {
                ...this.state.sensor,
                [name]: isBooleanValue ? value === 'true' : value
            }
        })

    }

    getSimsAutocomplete() {
        API.get(`/simcards/all?page=1&limit=3&iccid=${this.state.sensor.sim.iccid}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(res => {
                this.setState({ sims: res.data.content })
            }).catch(err => console.error(err))
    }

    handleThresholdValue(e, variableId) {
        const { name, value } = e.target
        const parsedValue = parseInt(value)
        this.setState({
            threshold: this.state.threshold.map(threshold => {
                if (threshold.variableId === variableId) {
                    return {
                        ...threshold,
                        [name]: isNaN(parsedValue) ? "" : parsedValue
                    }
                }
                return threshold
            })
        })
    }

    setSimValue(sim) {
        this.setState({
            sensor: {
                ...this.state.sensor,
                sim: {
                    iccid: sim.iccid,
                    id: sim.id
                }
            }
        })
    }

    setVariableToShow(e, soilId) {
        const { name, checked } = e.target
        this.setState({
            threshold: !checked ?
                this.state.threshold.filter(threshold => threshold.variableId !== name) :
                [
                    ...this.state.threshold,
                    {
                        // esta propiedad no se envía al backend pero se usa para saber a que sonda pertenece
                        // para poder mostrar los valores en el formulario
                        soilIdSensor: soilId,
                        variableId: name,
                        minValue: 0,
                        maxValue: 100,
                        minLimit: 0,
                        maxLimit: 100
                    }
                ]
        })
    }

    addSonda() {
        this.setState({
            soilSensor: [
                ...this.state.soilSensor,
                {
                    soilIdSensor: '',
                    soilNumber: this.state.soilSensor.length + 1,
                }
            ]
        })
    }

    handleSoilChange(e) {
        const { value, name } = e.target

        this.setState({
            soilSensor: this.state.soilSensor.map(soil => {
                if (soil.soilNumber === parseInt(name)) {
                    return {
                        ...soil,
                        soilIdSensor: value
                    }
                }
                return soil
            })
        })
    }

    handleSondaDelete(soilNumber) {
        this.setState({
            soilSensor: this.state.soilSensor.filter(soil => soil.soilNumber !== soilNumber),
            threshold: this.state.threshold.filter(threshold => threshold.soilIdSensor !== soilNumber)
        })
    }


    render() {
        const simsAutocomplete = this.state.sims.filter(sim => sim.iccid.includes(this.state.sensor.sim.iccid) && sim.iccid !== this.state.sensor.sim.iccid)
        return (
            <>
                <BarComponent propsBar={{ title: 'Agregar sensor', isMainPage: true }} />
                <section className="container">
                    {this.state.loading || !this.state.sensor ?
                        (<div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only" />
                            </Spinner>
                        </div>)
                        : (
                            <>
                                <Form onSubmit={this.handleSubmit} className="my-5">
                                    <div className='mt-2'>
                                        <Form.Label>Id de sensor<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={this.state.sensor.id} onChange={this.handleChange} maxLength={10} required type="text" name="id" />
                                    </div>
                                    <div className="mt-2">
                                        <Form.Label>Nombre de sensor<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={this.state.sensor.name} onChange={this.handleChange} required type="text" name="name" />
                                    </div>
                                    <div className="mt-2">
                                        <Form.Label>Activo<span className="text-danger">*</span></Form.Label>
                                        <select value={this.state.sensor.active} onChange={this.handleChange} required name="active">
                                            <option defaultValue disabled value="">Selecciona el estado</option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                    <div className="mt-2">
                                        <Form.Label>Inicio de operaciones<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={this.state.sensor.startOperation} onChange={this.handleChange} required type="date" name="startOperation" />
                                    </div>
                                    <div className="mt-2">
                                        <Form.Label>Descripción<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={this.state.sensor.description} maxLength={255} onChange={this.handleChange} required as="textarea" name="description" />
                                    </div>
                                    <div className='mt-2'>
                                        <Form.Label>Enviar alertas a correo<span className="text-danger">*</span></Form.Label>
                                        <div className='d-flex'>
                                            <div className='mr-2'>
                                                <input id="yes" type="radio" onChange={this.handleChange} checked={this.state.sensor.sendMail} name="sendMail" value={true} className='mr-1' />
                                                <label htmlFor="yes">Si</label>
                                            </div>
                                            <div>
                                                <input id="no" type="radio" onChange={this.handleChange} checked={!this.state.sensor.sendMail} name="sendMail" value={false} className='mr-1' />
                                                <label htmlFor="no">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-2 position-relative">
                                        <Form.Label>Id de sim<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={this.state.sensor.sim.iccid} onChange={this.handleChange} required type="text" name="sim" />
                                        {simsAutocomplete.length > 0 && this.state.sensor.sim.iccid.length > 0 && (
                                            <div style={{ zIndex: 20 }} className="position-absolute bg-white w-100 border rounded">
                                                {simsAutocomplete.map((sim, i) => (
                                                    <p key={i} onClick={() => this.setSimValue(sim)} className="p-1 m-0 autocomplete-option">{sim.iccid}</p>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <hr />

                                    <div className='my-3 d-flex flex-column'>
                                        <div className='limits-container'>
                                            {VARIABLES.filter(v => !v.sonda).map(variable => {
                                                const variableCheck = this.state.threshold.find(th => th.variableId.startsWith(variable.id))
                                                return (
                                                    <div key={variable.label} className="mt-2 d-flex flex-column limit-card-container">
                                                        <Form.Check type="checkbox" onChange={e => this.setVariableToShow(e)} checked={!!variableCheck} label={variable.label} name={variable.id} className='w-50' />

                                                        {variableCheck && (
                                                            <div className='limits-card d-flex'>
                                                                <div>
                                                                    <p>Rangos de medición del sensor</p>

                                                                    <Form.Label>Valor máximo %<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control required onChange={(e) => this.handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.maxValue} type="number" name="maxValue" />


                                                                    <Form.Label className='mt-3'>Valor mínimo %<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control required onChange={(e) => this.handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.minValue} type="number" name="minValue" />
                                                                </div>
                                                                <div>
                                                                    <p>Valores limites para las alertas</p>
                                                                    <Form.Label>Limite máximo %<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control required onChange={(e) => this.handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.maxLimit} type="number" name="maxLimit" />

                                                                    <Form.Label className='mt-3'>Limite mínimo %<span className="text-danger">*</span></Form.Label>
                                                                    <Form.Control required onChange={(e) => this.handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.minLimit} type="number" name="minLimit" />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <img src={addIcon}
                                            alt="Agregar sensor"
                                            className="add-icon my-2 align-self-end"
                                            onClick={this.addSonda}
                                            style={{ cursor: 'pointer' }}
                                            width="52"
                                        />

                                        {this.state.soilSensor.map((soilS) => (
                                            <div className='sonda-container' key={soilS.soilNumber}>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <h3>Sonda {soilS.soilNumber}</h3>

                                                    {soilS.soilNumber > 1 && (
                                                        <button
                                                            onClick={() => this.handleSondaDelete(soilS.soilNumber)}
                                                            className='btn py-2 btn-danger btn-sm'
                                                        >
                                                            Eliminar sonda
                                                        </button>
                                                    )}
                                                </div>


                                                <Form.Label>Tipo de sonda<span className="text-danger">*</span></Form.Label>
                                                <select value={soilS.soilIdSensor} onChange={this.handleSoilChange} name={soilS.soilNumber} required className='w-75'>
                                                    <option defaultValue disabled value="">Selecciona un tipo de sonda</option>
                                                    {this.state.soil_types.map((soil_type) => (
                                                        <option key={crypto.randomUUID()} value={soil_type.id}>{soil_type.id}</option>
                                                    ))}
                                                </select>


                                                <div className='limits-container'>
                                                    {VARIABLES.filter(v => v.sonda).map(variable => {
                                                        const variableCheck = this.state.threshold.find(th => th.variableId.startsWith(variable.id) && th.soilIdSensor === soilS.soilNumber)
                                                        return (
                                                            <div key={variable.label} className="mt-2 d-flex flex-column limit-card-container">
                                                                <Form.Check type="checkbox" checked={!!variableCheck} onChange={e => this.setVariableToShow(e, soilS.soilNumber)} label={variable.label} name={variable.id} className='w-50' />

                                                                {variableCheck && (
                                                                    <div className='limits-card d-flex'>
                                                                        <div>
                                                                            <p>Rangos de medición del sensor</p>

                                                                            <Form.Label>Valor máximo %<span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control onChange={(e) => this.handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.maxValue} type="number" name="maxValue" />


                                                                            <Form.Label className='mt-3'>Valor mínimo %<span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control onChange={(e) => this.handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.minValue} type="number" name="minValue" />
                                                                        </div>
                                                                        <div>
                                                                            <p>Valores limites para las alertas</p>
                                                                            <Form.Label>Limite máximo %<span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control onChange={(e) => this.handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.maxLimit} type="number" name="maxLimit" />

                                                                            <Form.Label className='mt-3'>Limite mínimo %<span className="text-danger">*</span></Form.Label>
                                                                            <Form.Control onChange={(e) => this.handleThresholdValue(e, variableCheck.variableId)} value={variableCheck.minLimit} type="number" name="minLimit" />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        ))}
                                    </div>


                                    <div className="mt-4 w-100 justify-content-center align-items-center d-flex">
                                        <Button type="submit" className="px-4 py-2">Agregar</Button>
                                        <Link to="/sensors-ad" className="btn px-4 py-2 btn-danger ml-2">Cancelar</Link>
                                    </div>
                                </Form>
                            </>
                        )}
                </section >
            </>
        )
    }
}

export default SensorsAddView