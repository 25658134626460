import React, { Component } from 'react';
import SensorCard from "./SensorCard";
import API from './../api/api';
import BarComponent from "../userbar/BarComponent";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/sensorsview.css';
import LoadingComponent from "../loading/LoadingComponent";

import { withRouter } from "react-router-dom";

import { SENSORS_LOADING_MESSAGE } from "../utils/Constants";

class SensorsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sensors: [],
            userProps: {},
            isFetched: false,
            isError: false,
            showSensors: false,
            currentSensorName: '',
            showConfig: false,
            _isMounted: false,
        }
    }



    handleShowSensors = (e) => {
        this.setState({ showSensors: true })
    };

    setCurrentSensorName = (name) => this.setState({ currentSensorName: name })

    componentDidMount() {
        this._isMounted = true;
        if (localStorage.getItem('user-email')) {
            setTimeout(this.handleShowSensors, 1500);
            API.get('/sensors/sensors-from', {
                params: {
                    'idUser': localStorage.getItem('user-email')
                },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
                .then((res) => {
                    const sensors = res.data;
                    if (this._isMounted) {
                        this.setState({ sensors });
                        this.setState({ isFetched: true });
                    }

                }).catch((error) => {
                    if (this._isMounted) {
                        this.setState({ isFetched: false });
                        this.setState({ isError: true });
                    }
                    localStorage.removeItem('user-jwt');
                    localStorage.removeItem('actual-sensor');
                    this.props.history.push("/");
                });
        } else {
            this.props.history.push("/error");
        }
    }

    render() {
        const loadingProps = {
            message: SENSORS_LOADING_MESSAGE,
            type: "primary"
        }
        const propsBar = {
            isMainPage: true,
            title: 'Sensores'
        }
        if (this.state.isFetched && !this.state.isError && this.state.showSensors)
            return (
                <div id="todo" >
                    <div>
                        <BarComponent propsBar={propsBar} />
                        <Container id="tarjetasSensores">
                            {this.state.sensors.map(sensor => (
                                <Row key={sensor.id}>
                                    <SensorCard setSensorNameFunction={this.setCurrentSensorName} handleShow={this.handleShow} key={sensor.id} values={sensor} />
                                </Row>
                            ))}
                        </Container>
                    </div>
                </div>


            );
        else return <div>
            <LoadingComponent data={loadingProps} />
        </div>

    }

    componentWillUnmount() {
        this.setState({
            sensors: [],
            userProps: {},
            isFetched: false,
            isError: false,
            showSensors: false,
            currentSensorName: '',
            showConfig: false
        });
        this._isMounted = false;
    }

}


export default withRouter(SensorsView);