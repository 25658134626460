import { Component } from "react";
import { Button, Form, Pagination, Spinner } from "react-bootstrap";
import BarComponent from '../userbar/BarComponent'
import '../../styles/crops.css'
import addIcon from '../../img/add-icon.svg'
import editIcon from '../../img/edit_icon.png'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import searchIcon from '../../img/search-icon.svg'
import DeleteCrop from "./DeleteCrop";
import API from "../api/api";

const itemsPerPage = 5

class CropsView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            crops: [],
            currentPage: 1,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.getCrops = this.getCrops.bind(this)
    }

    // get de cultivos
    componentDidMount() {
        this.getCrops(1)
    }

    getCrops(page) {
        this.setState({ loading: true })
        API.get(`/crops/all?page=${page}&limit=${itemsPerPage}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(res => {
                this.setState({ crops: res.data.content, totalPages: res.data.totalPages, })
            })
            .catch(err => console.log(err))
            .finally(() => this.setState({ loading: false }))
    }

    handleSubmit(e) {
        e.preventDefault()
        const filterValues = Object.fromEntries(new FormData(e.target))

        this.setState({ loading: true })
        API.get(`/crops/all?page=1&limit=${itemsPerPage}&${filterValues.name ? `name=${filterValues.name}` : ''}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(res => {
                this.setState({ crops: res.data.content, totalPages: res.data.totalPages, currentPage: 1 })
            })
            .catch(err => console.log(err))
            .finally(() => this.setState({ loading: false }))
    }

    handlePageChange(page) {
        if (page === this.state.currentPage || page < 1 || page > this.state.totalPages) return
        this.setState({ currentPage: page })
        this.getCrops(page)
    }

    render() {
        return (
            <>
                <BarComponent propsBar={{ title: 'Cultivos', isMainPage: false }} />
                <section className="container">

                    <header className="d-flex justify-content-center flex-column mt-5">

                        <Form onSubmit={this.handleSubmit} className="d-flex flex-column">
                            <div className="position-relative d-flex">
                                <Form.Control type="text" name="name" placeholder="Id sensor" className="pl-5" />
                                <div style={{ top: -2 }} className="position-absolute translate-middle-y p-2 pl-3">
                                    <img src={searchIcon} width={19} alt="search" />
                                </div>

                                <Button type="submit" className="ml-2 px-4">Buscar</Button>
                            </div>
                        </Form>

                        <hr width='90%' />

                        <Link to="/crops/add" className="align-self-end">
                            <img src={addIcon}
                                alt="Agregar cultivo"
                                className="add-icon"
                                width="52"
                            />
                        </Link>
                    </header>

                    <div className="sims-container text-center mb-5 my-3">
                        {this.state.loading ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                                <Spinner animation="border" role="status" />
                            </div>
                        )
                            :
                            this.state.crops.length === 0 && !this.state.loading
                                ? <h2>No hay resultados con los filtros seleccionados</h2>
                                : (
                                    this.state.crops.map(crop => (
                                        <div key={crop.id} className="border card rounded shadow-sm bg-light mb-3 align-items-center p-2">
                                            <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0">Empresa</p>
                                                    <h5 className="mb-0">{crop.company}</h5>
                                                </div>
                                            </div>
                                            <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0">Nombre</p>
                                                    <h5 className="mb-0">{crop.name}</h5>
                                                </div>
                                            </div>
                                            <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0">Ubicación</p>
                                                    <h5 className="mb-0">{crop.location}</h5>
                                                </div>
                                            </div>
                                            <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0">Cultivo</p>
                                                    <h5 className="mb-0">{crop.crop}</h5>
                                                </div>
                                            </div>

                                            <div style={{ flex: '1', minWidth: '0' }} className="text-center p-2 buttons-card">
                                                <img
                                                    src={editIcon}
                                                    alt="Editar"
                                                    width="25"
                                                    onClick={() => this.props.history.push(`/crops/edit/${crop.id}`)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <DeleteCrop getCrops={this.getCrops} cropId={crop.id} />
                                            </div>
                                        </div>
                                    ))
                                )
                        }
                    </div>

                    <Pagination className="d-flex justify-content-center">
                        <Pagination.Prev onClick={() => this.handlePageChange(this.state.currentPage - 1)} />
                        {Array.from({ length: this.state.totalPages }, (_, i) => (
                            <Pagination.Item key={i} active={i + 1 === this.state.currentPage} onClick={() => this.handlePageChange(i + 1)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => this.handlePageChange(this.state.currentPage + 1)} />
                    </Pagination>
                </section>
            </>
        )
    }
}

export default CropsView