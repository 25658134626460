import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../styles/datanotfound.css';

class GraphDataNotFound extends Component {
    constructor(props) {
        super(props);

        this.returnViewSensors = this.returnViewSensors.bind(this);
    }

    returnViewSensors() {
        localStorage.removeItem('actual-sensor');
        this.props.history.push('/sensors-view');
    }

    render() {
        return (
            <div className="container-not-found">
                <p>No se encontraron datos</p>
                <button onClick={this.returnViewSensors} className="return">
                    Ver sensores
                </button>
            </div>
        );
    }
}

export default withRouter(GraphDataNotFound);
