import { Component } from "react";
import BarComponent from "../userbar/BarComponent";
import '../../styles/resumen.css'

const data = [
    {
        id: 1,
        title: 'Dispositivos operando',
        value: 15
    },
    {
        id: 2,
        title: 'Dispositivos en stock',
        value: 10
    },
    {
        id: 3,
        title: 'Dispositivos en contruccion',
        value: 5
    },
    {
        id: 4,
        title: 'Soportes pendientes',
        value: 1
    },
    {
        id: 5,
        title: 'Promedio de uso de plataforma por usuario/semana',
        value: '1:30'    
    },
    {
        id: 6,
        title: 'Promedio de sesiones en plataforma por usuario/mes',
        value: 4    
    },
    {
        id: 7,
        title: 'Dispositivos con +4h sin envíos',
        value: 2
    },
    {
        id: 8,
        title: 'Reportes generados',
        value: 15
    },
    {
        id: 9,
        title: 'Alertas generadas por mes',
        value: 50
    },
    {
        id: 10,
        title: 'Cultivos monitorizadas',
        value: 4
    },
    {
        id: 11,
        title: 'Departamentos monitoreados',
        value: 2
    },
    {
        id: 12,
        title: 'Clientes únicos',
        value: 10
    }
]

class ResumenView extends Component {
    render () {
        return (
            <>
                <BarComponent propsBar={{ title: 'Resumen', isMainPage: false }} />
                <section className="container">
                    <div className="resumen-cards-container">
                        {data.map(item => (
                            <div key={item.id} className="resumen-card">
                                <h3>{item.value}</h3>
                                <h5>{item.title}</h5>
                            </div>                        
                        ))}
                    </div>
                </section>
            </>
        )
    }
}

export default ResumenView;