import React,{Component} from 'react';

import "../../styles/graphs.css";
import Container from "react-bootstrap/Container";
import "../../styles/error.css";

export default class ErrorComponent extends Component{
    constructor(props){
        super(props);
        this.state={
            errorMessage: "Error"
        }
        this.handleLogout=this.handleLogout.bind(this);
    }

    handleLogout(){
        localStorage.clear();
        this.props.history.push("/");
    }

    render(){
        return(
            <Container id="errorContainer" className="text-center align-self-center">
            <h2>
                Se ha presentado un error. Intenta ingresar nuevamente
                <a id="ButtonResetDate" href="/#" onClick={this.handleLogout}> aquí.</a></h2>
                <h3>Si el problema persiste, contáctanos a info@vitsensors.com</h3>
            </Container>
        );
    }
}
