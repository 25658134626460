import React, { Component } from 'react';
import VariableGraph from './VariableGraph';
import '../../styles/graphs.css';
import Container from 'react-bootstrap/Container';
import { withRouter } from 'react-router-dom';

import '../../styles/loading.css';
import BarComponent from '../userbar/BarComponent';

import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import API from '../api/api';
import LoadingComponent from '../loading/LoadingComponent';
import { GRAPHS_LOADING_MESSAGE } from '../utils/Constants';
import GraphDataNotFound from './GraphDataNotFound';
import {
    operationForChangeVbValue,
    operationForChangeCeValue,
    operationForChangePhValue
} from '../utils/AdjustData';

//  modal:any;
class VariableGraphContainer extends Component {
    _isMounted = false;
    ERROR_MESSAGE_NO_DATA_AVALIABLE =
        'Parece que el sensor no ha tenido actividad. Esto puede deberse a que quizás ha sido reciente su instalación. Compruebe en un momento o comuníquese con nosotros.';
    ERROR_MESSAGE_DEFAULT =
        'Se ha presentado un inconveniente obteniendo los datos de su sensor. Comuníquese con nosotros.';
    variablesIdList = [];
    
    constructor(props) {
        super(props);
        this.state = {
            sensor: localStorage.getItem('actual-sensor')
                ? JSON.parse(localStorage.getItem('actual-sensor'))
                : null,
            loaded: false,
            showGraphs: false,
            variablesData: [],
        };
        // this.variablesIdList = this.state.sensor.sensorVariablesString;
        this.variablesIdList = this.orderSensorVariablesString(this.state.sensor.sensorVariablesString);
    }

    componentDidMount() {
        const startDate = format(
            addDays(new Date(), -1),
            "yyyy-MM-dd'T'00:00:00.000'-05:00'"
        );
        const endDate = format(
            addDays(new Date(), 1),
            "yyyy-MM-dd'T'23:59:59.000'-05:00'"
        );

        this.requestAllMeasures(startDate, endDate);
    }

    async requestAllMeasures(startDate, endDate) {
        try {
            const responses = await Promise.all(
                this.variablesIdList.map((idVariable) => {
                    const requestOptions = {
                        params: {
                            idSensor: this.state.sensor.id,
                            idVariable: idVariable,
                            startDate: startDate,
                            endDate: endDate,
                            recursive: true,
                        },
                        headers: {
                            Authorization:
                                'Bearer ' + localStorage.getItem('user-jwt'),
                        },
                    };

                    return API.get('/sensors/measurements', requestOptions);
                })
            );

            const dataFound = responses[0].data.measures.length !== 0;

            this.setState({
                loaded: true,
                showGraphs: dataFound,
                variablesData: this.setThresholdForEachGraph(responses),
            });
        } catch (error) {
            this.props.history.push('/error');
        }
    }

    setThresholdForEachGraph(dataResponse) {
        return dataResponse.map((sensor) => {
            const data = sensor.data;

            if (data.variableId === 'VB')
                operationForChangeVbValue(data.measures);

            if (data.variableId === 'CE')
                operationForChangeCeValue(data.measures, this.state.sensor.id);
            if (data.variableId === 'PH')
                operationForChangePhValue(data.measures, this.state.sensor.id);
            return {
                data: [
                    {
                        id: data.variableId,
                        data: data.measures,
                    },
                ],
                varId: data.variableId,
                varName: data.variableName,
                units: data.variableUnit,
                initialMaxThreshold: data.threshold.maxLimit,
                actualMaxThreshold: data.threshold.maxValue,
                initialMinThreshold: data.threshold.minLimit,
                actualMinThreshold: data.threshold.minValue,
                sensorId: this.state.sensor.id,
            };
        });
    }

    orderSensorVariablesString(receivedArray){
        const orderReference = ['TA', 'HA', 'Rd','TS', 'HS','CE','PH','NI', 'PI', 'KI',
                                'TS2','HS2','CE2','PH2','NI2','PI2','KI2','VB','CB','VP','CP','Rs']
                                
        // Ordenar el array según el orden de la referencia y mantener elementos no presentes en la referencia al final
        const sortedArray = [...receivedArray].sort((a, b) => {
            const indexA = orderReference.indexOf(a);
            const indexB = orderReference.indexOf(b);
        
            // Si ambos elementos están en la referencia, compara sus índices
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
            }
        
            // Si solo uno está en la referencia, colócalo antes
            if (indexA !== -1) {
                return -1;
            }
        
            if (indexB !== -1) {
                return 1;
            }
        
            // Si ninguno está en la referencia, no cambies su orden relativo
            return 0;
        });
        return sortedArray;
    }
    render() {
        const loadingProps = {
            message: GRAPHS_LOADING_MESSAGE,
            type: 'success',
        };

        const propsBar = {
            isMainPage: false,
            title: this.state.sensor.name,
        };

        if (this.state.loaded) {
            return (
                <div>
                    <BarComponent
                        propsBar={propsBar}
                        show={this.state.showGraphs}
                        hide={!this.state.showGraphs}
                    />
                    {this.state.showGraphs ? (
                        <Container fluid="xl">
                            {this.state.variablesData.map(
                                (graphData, index) => (
                                    <VariableGraph
                                        key={index}
                                        values={{ graphData }}
                                    />
                                )
                            )}
                        </Container>
                    ) : (
                        <GraphDataNotFound />
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        <LoadingComponent data={loadingProps} />
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(VariableGraphContainer);
