import { Route, Redirect } from 'react-router-dom'

export default function RoleProtectedComponent({ component: Component, ...rest }) {
    const authorities = JSON.parse(localStorage.getItem("authorities"));
    // const isAdmin = localStorage.getItem("user-role") === "admin";
    const isAdmin = authorities.includes("ROLE_ADMIN");

    return (
        <Route
            {...rest}
            render={(props) =>
                isAdmin ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    )
}