import React, {Component} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import "../../styles/loading.css";

export default class LoadingComponent extends  Component{
    constructor(props){
        super(props);
        this.state={
            message: this.props.data.message,
            type: this.props.data.type
        }
    }


    render(){
        return(
            <Container id="loadingContainer" className="text-center align-self-center">
                <h2>{this.state.message}</h2>
                <Spinner animation="border" role="status" variant={this.state.type}>
                    <span className="sr-only"/>
                </Spinner>
            </Container>
        );
    }

}