export const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];
export const WEEKDAYS_LONG = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
];
export const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

export const graphColors = {
    'CB': '#136caf',
    'CP':'#03312e',
    'Ct':'#087e8b',
    'H1':'#1a2d3e',
    'H2':'#75158A',
    'H3':'#008744',
    'HA':'#0057e7',
    'Rd':'#d62d20',
    'Rs':'#ffa700',
    'T1':'#ffafff',
    'T2':'#08fabd',
    'T3':'#0c090d',
    'TA':'#f9c22e',
    'VB':'#2e933c',
    'VP':'#28666e',
    'CE':'#28666e',
    'PH':'#28666e',
    'NI':'#28666e',
    'PI':'#28666e',
    'KI':'#28666e',
    'HS':'#28666e',
    'TS':'#28666e',
}