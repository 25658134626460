import roses_back from '../../img/roses.jpg';
import avocado_back from '../../img/avocado.jpg';
import coffee_back from '../../img/coffee.jpg';
import palm_back from '../../img/palm.jpg';
import tomatoes_back from '../../img/tomatoes.jpg';
import gulupa_back from '../../img/gulupa.jpg';
import arandano_back from '../../img/arandano.jpg';
import aromaticas_back from '../../img/hierbabuena.jpg';
import default_back from '../../img/plants.jpg';


export default  class SensorsUtils{
    static getBackgroundSensor(cropType){
        switch (cropType) {
            case 'Flores': return roses_back;
            case 'Aguacate': return avocado_back;
            case 'Café': return coffee_back;
            case 'Palma': return palm_back;
            case 'Tomate': return tomatoes_back;
            case 'Gulupa': return gulupa_back;
            case 'Arándanos': return arandano_back;
            case 'Aromáticas': return aromaticas_back
            default: return default_back;
        }
    }
}