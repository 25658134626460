import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import Login from "./components/login/Login";
import { HashRouter, Route, Switch } from "react-router-dom";
import SensorsView from "./components/sensorsview/SensorsView";
import VariableGraphContainer from "./components/sensorgraphs/VariableGraphContainer";
import ErrorComponent from "./components/error/ErrorComponent";
import { isMobile } from "react-device-detect";
import { NOT_ALLOWED_ORIENTATION } from "./components/utils/Constants";
import { Modal } from "react-bootstrap";
import Simcards from "./components/simcards/Simcards";
import SimcardAddView from "./components/simcards/SimcardAddView";
import SimcardEditView from "./components/simcards/SimcardEditView";
import SensorsAd from "./components/sensorsAd/SensorsAd";
import SensorsAddView from "./components/sensorsAd/SensorsAddView";
import SensorsEditView from "./components/sensorsAd/SensorsEditView";
import UsersView from "./components/users/UsersView";
import UsersAddView from "./components/users/UsersAddView";
import UsersEditView from "./components/users/UsersEditView";
import CropsView from "./components/crops/CropsView";
import CropsAddView from "./components/crops/CropsAddView";
import CropsEditView from "./components/crops/CropsEditView";
import ResumenView from "./components/resumen/ResumenView";
import RoleProtectedComponent from "./components/roleProtectedComponent/RoleProtectedComponent";

const Root = () => {
  const [orientation, setOrientation] = useState(
    window.orientation
  );

  useEffect(() => {
    window.addEventListener("orientationchange", (event) => {
      setOrientation(window.orientation);
    });
  }, []);

  if (isMobile && Object.values(NOT_ALLOWED_ORIENTATION).includes(orientation)) {
    return (
      <Modal show={true} onHide={() => { }}>
        <Modal.Body>
          Para poder continuar, por favor cambia la orientación del dispositivo
          a horizontal
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" name="Login Page" component={Login} />
        <Route
          path="/sensors-view"
          name="Sensors Cards View"
          component={SensorsView}
        />
        <Route path="/graphs" component={VariableGraphContainer} />

        <RoleProtectedComponent exact path="/simcards" component={Simcards} />
        <RoleProtectedComponent path="/simcards/add" component={SimcardAddView} />
        <RoleProtectedComponent path="/simcards/edit/:id" component={SimcardEditView} />

        <RoleProtectedComponent exact path="/sensors-ad" component={SensorsAd} />
        <RoleProtectedComponent path="/sensors-ad/add" component={SensorsAddView} />
        <RoleProtectedComponent path="/sensors-ad/edit/:id" component={SensorsEditView} />

        <RoleProtectedComponent exact path="/users" component={UsersView} />
        <RoleProtectedComponent path="/users/add" component={UsersAddView} />
        <RoleProtectedComponent exact path="/users/edit/:id" component={UsersEditView} />

        <RoleProtectedComponent exact path="/crops" component={CropsView} />
        <RoleProtectedComponent exact path="/crops/add" component={CropsAddView} />
        <RoleProtectedComponent exact path="/crops/edit/:id" component={CropsEditView} />

        <RoleProtectedComponent exact path="/resumen" component={ResumenView} />

        <Route component={ErrorComponent} />
      </Switch>
    </HashRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
