import React,{Component} from 'react';
import format from 'date-fns/format';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import notifications_image from '../../img/notifications_2.png';
import profile_icon from '../../img/user.png';
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import API from "../api/api";
import { withRouter } from 'react-router-dom';
import mainLogoBlanco from '../../img/Logo_blanco.png';
import backArrow from '../../img/previous.png';
import '../../styles/nav.css';

class BarComponent extends Component{
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state={
            listNotifications:[],
            isMainPage: (this.props.propsBar.isMainPage),
            testing: false,
            title:(this.props.propsBar.title)?(this.props.propsBar.title):''
        }
        this.onClickImageHandler = this.onClickImageHandler.bind(this);
        this.logoutHandler = this.logoutHandler.bind(this);
    }

    componentDidMount() {
        /*
        if(localStorage.getItem('user-email') && !this.state.testing){
            this._isMounted = true;
            API.get('/sensors/get-alerts',{
                params: {
                    'idUser':localStorage.getItem('user-email')
                },
                headers:{
                    Authorization: 'Bearer '+localStorage.getItem('user-jwt')
                }
            })
                .then((res)=>{
                    this.setState({listNotifications:res.data})
                })
                .catch((error)=>{
                    if(error.response.status === 401){
                        localStorage.removeItem('user-jwt');
                        localStorage.removeItem('actual-sensor');
                        this.props.history.push('/');
                    } else this.props.history.push("/error");
                })
        }*/
    }


    logoutHandler(e){
        API.get('/user/updateDate',{
            params: {
                'email': localStorage.getItem('user-email'),
                'updateDate': new Date().toISOString()
            },
            headers:{
                Authorization: 'Bearer '+localStorage.getItem('user-jwt')
            }
        })
            .then((res)=>{
                localStorage.clear();
                this.props.history.push('/');
            })
            .catch((error)=>{
                if(error.response.status === 401){
                    localStorage.removeItem('user-jwt');
                    localStorage.removeItem('actual-sensor');
                    this.props.history.push('/');
                }else this.props.history.push("/error");
                }
            )
    }

    onClickImageHandler(){
        this.props.history.push('/sensors-view');
    }

    /*TODO: Icon of notifications acording if it's checked or not*/
    render(){
        return(
            <Navbar bg="dark" variant="dark" className="justify-content-between" id="nav-bar">
                <Navbar.Brand onClick={this.onClickImageHandler}><img
                            src={this.state.isMainPage?mainLogoBlanco:backArrow}
                            width="60"
                            height="35"
                            className="d-inline-block align-top"
                            alt="logo icon"
                            id="logo-vit"
                        />

                </Navbar.Brand>

                <h3 id="nav-title">{this.state.title}</h3>
                <Nav>
                    <NavDropdown className="mr-sm-2" alignRight title={
                        <img
                            src={notifications_image}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="logo icon"
                        />
                    } id="nav-dropdown-basic" >
                        <div className="notifications">
                        {this.state.listNotifications.length!==0?
                            <ListGroup id="miList" >
                            {this.state.listNotifications.map((notification,index) => (
                                    <ListGroup.Item className="desple" key={index}>Sensor: {notification.idSensor}, Variable: {notification.variable.readableName}, Fecha: {format(new Date(notification.dateMeasure),"yyyy-MM-dd")}</ListGroup.Item>
                            ))}
                        </ListGroup>:<div>No hay notificaciones</div>
                        }
                        </div>
                    </NavDropdown>

                    <NavDropdown alignRight title={
                        <img
                            src={profile_icon}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="profile icon"
                            id="miImage"
                        />
                    } id="nav-dropdown" >
                        <div className="conte">
                            {/*<Dropdown.Item className="desple pt-2"><i className= "fa fa-cog"/>Configuraciones</Dropdown.Item>*/}
                            <Dropdown.Item className="desple  pt-2" id="dd" onClick={this.logoutHandler} ><i className= "fa fa-sign-out"/>Salir</Dropdown.Item>
                        </div>
                    </NavDropdown>

                </Nav>

            </Navbar>
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

}

export default withRouter(BarComponent);