import { Component } from "react";
import { Button, Form, Pagination, Spinner } from "react-bootstrap";
import BarComponent from '../userbar/BarComponent'
import addIcon from '../../img/add-icon.svg'
import editIcon from '../../img/edit_icon.png'
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import '../../styles/sensorsad.css'
import searchIcon from '../../img/search-icon.svg'
import DeleteSensorModal from "./DeleteSensorModal";
import API from "../api/api";

const itemsPerPage = 5

class SensorsAd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sensors: [],
            filteredSensors: [],
            currentPage: 1,
            filters: {
                idSensor: "",
                active: ""
            },
            loading: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
    }

    // get de sensores
    componentDidMount() {
        this.getSensors(1)
    }

    handleSubmit(e) {
        e.preventDefault()

        const values = Object.fromEntries(new FormData(e.target))

        // si el valor de un campo es un string vacio, lo eliminamos del objeto
        for (const fil in values) {
            if (!values[fil].trim()) delete values[fil]
        }

        const filterValues = Object.entries(values).join('&').replaceAll(',', '=')

        this.setState({ loading: true })
        API.get(`/sensors/all?page=1&limit=${itemsPerPage}&${filterValues}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(({ data }) => {
                this.setState({
                    sensors: data.content,
                    totalPages: data.totalPages,
                    currentPage: 1
                })
            })
            .catch(() => this.setState({ error: true }))
            .finally(() => this.setState({ loading: false }))
    }

    getSensors(page) {
        this.setState({ loading: true })
        API.get(`/sensors/all?page=${page}&limit=${itemsPerPage}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(({ data }) => {
                this.setState({
                    sensors: data.content,
                    totalPages: data.totalPages
                })
            })
            .catch(() => this.setState({ error: true }))
            .finally(() => this.setState({ loading: false }))
    }

    handleChange(e) {
        const { name, value } = e.target
        const isBooleanValue = name === 'active' && value !== ""
        this.setState({
            filters: {
                ...this.state.filters,
                [name]: isBooleanValue ? value === "true" : value
            }
        })
    }

    handlePageChange(page) {
        if (page === this.state.currentPage || page < 1 || page > this.state.totalPages) return
        this.setState({ currentPage: page })
        this.getSensors(page)
    }

    render() {
        return (
            <>
                <BarComponent propsBar={{ title: 'Sensores', isMainPage: false }} />
                <section className="container">

                    <header className="d-flex justify-content-center flex-column mt-5">

                        <Form onSubmit={this.handleSubmit} className="d-flex flex-column">
                            <div className="position-relative d-flex">
                                <Form.Control type="text" onChange={this.handleChange} value={this.state.filters.idSensor} name="idSensor" placeholder="ID del sensor" className="pl-5" />
                                <div style={{ top: -2 }} className="position-absolute translate-middle-y p-2 pl-3">
                                    <img src={searchIcon} width={19} alt="search" />
                                </div>

                                <Button type="submit" className="ml-2 px-4">Buscar</Button>
                            </div>

                            <div className="d-flex select-container align-items-center align-self-center mt-4">

                                <p className="m-0 mr-2">Estado</p>
                                <select name="active" onChange={this.handleChange} value={this.state.filters.active} className="mr-2">
                                    <option value="">Todos</option>
                                    <option value={true}>Activo</option>
                                    <option value={false}>No activo</option>
                                </select>

                            </div>
                        </Form>

                        <hr width='90%' />

                        <Link to="/sensors-ad/add" className="align-self-end">
                            <img src={addIcon}
                                alt="Agregar sensor"
                                className="add-icon"
                                width="52"
                            />
                        </Link>
                    </header>

                    <div className="sims-container text-center mb-5 my-3">
                        {this.state.loading && (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '380px' }}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only" />
                                </Spinner>
                            </div>
                        )}
                        {this.state.error && <h2>Error al cargar los sensores</h2>}
                        {this.state.sensors.length === 0 && !this.state.loading && !this.state.error
                            ? <h2>No hay resultados con los filtros seleccionados</h2>
                            : (
                                this.state.sensors.map(sensor => (
                                    <div key={sensor.id} className="border card rounded shadow-sm bg-light mb-3 align-items-center p-2">
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">Id sensor</p>
                                                <h5 className="mb-0">{sensor.id}</h5>
                                            </div>
                                        </div>
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">Nombre Sensor</p>
                                                <h5 className="mb-0">{sensor.name}</h5>
                                            </div>
                                        </div>
                                        <div style={{ flex: '1', minWidth: '0' }} className="p-2">
                                            <div className="d-flex flex-column">
                                                <p className="mb-0">Estado</p>
                                                <h5 className="mb-0 text-capitalize">{sensor.active ? 'Activo' : 'Inactivo'}</h5>
                                            </div>
                                        </div>
                                        <div style={{ flex: '1', minWidth: '0' }} className="text-center p-2 buttons-card">
                                            <img
                                                src={editIcon}
                                                alt="Editar"
                                                width="25"
                                                onClick={() => this.props.history.push(`/sensors-ad/edit/${sensor.id}`)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <DeleteSensorModal sensorId={sensor.id} getSensors={() => this.getSensors(this.state.currentPage)} />
                                        </div>
                                    </div>
                                ))
                            )

                        }
                    </div>

                    <Pagination className="d-flex justify-content-center">
                        <Pagination.Prev onClick={() => this.handlePageChange(this.state.currentPage - 1)} />
                        {Array.from({ length: this.state.totalPages }, (_, i) => (
                            <Pagination.Item key={i} active={i + 1 === this.state.currentPage} onClick={() => this.handlePageChange(i + 1)}>
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => this.handlePageChange(this.state.currentPage + 1)} />
                    </Pagination>
                </section>
            </>
        )
    }
}

export default SensorsAd