export const objectHasEmptyValues = (obj) => {
    for (const key in obj) {
        const value = obj[key]
        if (typeof value === 'string' && value.trim() === '') return true        
        if (typeof value === 'number' && isNaN(value)) return true
    }
    return false
}

// retorna un objeto con los valores del segundo objeto que son diferentes al primer objeto
export const objectDiffValues = (obj1, obj2) => {
    const diff = {}
    for (const key in obj2) { 
        if (obj2[key] !== obj1[key]) diff[key] = obj2[key]
    }
    return diff
}