import { Component } from 'react';
import deleteIcon from '../../img/delete-icon.svg'
import { Button, Modal } from 'react-bootstrap';
import API from '../api/api';

class DeleteUserModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
        this.deleteUser = this.deleteUser.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    deleteUser(user) {        
        API.delete(`user/${user}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(() => {
                this.handleClose()
                this.props.getUsers()
            })
            .catch((err) => console.log(err))
            .finally(() => this.handleClose())
    }

    handleClose() {
        this.setState({ show: false })
    }

    render() {
        return (
            <>
                <img
                    src={deleteIcon}
                    alt="Eliminar"
                    width="32"
                    className="ml-3"
                    onClick={() => this.setState({ show: true })}
                    style={{ cursor: 'pointer' }}
                />
                <Modal style={{ margin: '16em 0' }} show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar usuario</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Estás seguro de eliminar el usuario <span className='font-weight-bold'>{this.props.user.firstName}</span> con correo: {this.props.user.email}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.deleteUser(this.props.user.email)}>
                            Eliminar
                        </Button>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default DeleteUserModal