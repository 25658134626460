import { Component } from 'react';
import deleteIcon from '../../img/delete-icon.svg'
import { Button, Modal } from 'react-bootstrap';
import API from '../api/api';

class DeleteSensorModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
        this.deleteSensor = this.deleteSensor.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    deleteSensor(sensorId) {
        API.delete(`sensors/${sensorId}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(() => {
                this.handleClose()
                this.props.getSensors()
            })
            .catch((err) => console.log(err))
    }

    handleClose() {
        this.setState({ show: false })
    }

    render() {
        return (
            <>
                <img
                    src={deleteIcon}
                    alt="Eliminar"
                    width="32"
                    className="ml-3"
                    onClick={() => this.setState({ show: true })}
                    style={{ cursor: 'pointer' }}
                />
                <Modal style={{margin: '16em 0'}} show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar sensor</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>¿Estás seguro de eliminar el sensor con ID: {this.props.sensorId}?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.deleteSensor(this.props.sensorId)}>
                            Eliminar
                        </Button>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default DeleteSensorModal