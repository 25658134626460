import { Component } from "react";
import { Button, Form, Pagination } from "react-bootstrap";
import { objectHasEmptyValues } from "../utils/FormValidations"; //objectDiffValues,
import BarComponent from "../userbar/BarComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import searchIcon from '../../img/search-icon.svg'
import eyeOpen from '../../img/eye-icon.svg'
import eyeClose from '../../img/eye-slash-icon.svg'

import '../../styles/userview.css'
import API from "../api/api";

const initialValues = {
    firstName: "asd",
    lastName: "sad",
    email: "asda@gmail.com",
    address: "dsfdsf",
    password: "asdasd",
    phone: "123123",
    sensors: []
}

const itemsPerPage = 9

class UsersEditView extends Component {
    originalSensors = {}
    originalUser = initialValues
    constructor(props) {
        super(props)
        this.state = {
            user: initialValues,
            sensorSearch: '',
            sensors: [],
            currentPage: 1,
            totalPages: 1,
            showPassword: false
        }
        this.createUser = this.createUser.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleSensorSearch = this.handleSensorSearch.bind(this)
        this.handleSensorSelected = this.handleSensorSelected.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.getSensors = this.getSensors.bind(this)
    }

    componentDidMount() {
        this.getSensors(1)
    }

    getSensors(page, search = 'test') {
        API.get(`/sensors/all?page=${page}&limit=${itemsPerPage}&${search}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(({ data }) => {
                this.setState({ sensors: data.content, totalPages: data.totalPages, currentPage: page })
            })
            .catch(() => alert('Error al obtener los sensores'))
    }

    createUser(e) {
        e.preventDefault()

        const { sensors, ...restOfUser } = this.state.user
        if (objectHasEmptyValues(restOfUser)) return alert('Todos los campos son requeridos')

        API.post('/user/create', { user: restOfUser, sensors }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(() => {
                alert('Usuario creado correctamente')
                this.setState({ user: initialValues })
            })
            .catch((err) => {
                alert(err.response.data.message || 'Error al crear el usuario')
            })
    }

    handleChange(e) {
        const { name, value } = e.target
        if (name === 'phone' && value.length > 10) return
        this.setState({
            user: {
                ...this.state.user,
                [name]: value
            }
        })
    }

    // evita que al presionar 'Enter' en el input de busqueda de sensor,
    // se haga submit del form
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    handleSensorSearch() {
        API.get(`/sensors/all?page=1&limit=${itemsPerPage}&idSensor=${this.state.sensorSearch}`,{
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('user-jwt')
                }
            })
            .then(({ data }) => {
                this.setState({ sensors: data.content, totalPages: data.totalPages, currentPage: 1 })
            })
            .catch(() => alert('Error al obtener los sensores'))
    }

    handleSensorSelected(e) {
        const { checked, id } = e.target
        this.setState({
            user: {
                ...this.state.user,
                sensors: checked ? [...this.state.user.sensors, id] : this.state.user.sensors.filter(sensor => sensor !== id)
            }
        })
    }

    handlePageChange(page) {
        if (page === this.state.currentPage || page < 1 || page > this.state.totalPages) return
        this.setState({ currentPage: page })
        this.getSensors(page)
    }

    render() {
        const isButtonDisabled = objectHasEmptyValues(this.state.user)
        return (
            <>
                <BarComponent propsBar={{ title: 'Agregar usuario', isMainPage: true }} />
                <section className="container">
                    <Form onSubmit={this.createUser} className="my-3">
                        <div className="d-flex w-100">
                            <div className="mt-2 mr-3 w-100">
                                <Form.Label>Nombres<span className="text-danger">*</span></Form.Label>
                                <Form.Control required onChange={this.handleChange} value={this.state.user.firstName} type="text" name="firstName" />
                            </div>
                            <div className="mt-2 w-100">
                                <Form.Label>Apellidos<span className="text-danger">*</span></Form.Label>
                                <Form.Control required onChange={this.handleChange} value={this.state.user.lastName} type="text" name="lastName" />
                            </div>
                        </div>
                        <div className="mt-2">
                            <Form.Label>Correo<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={this.handleChange} value={this.state.user.email} type="email" name="email" />
                        </div>
                        <div className="mt-2">
                            <Form.Label>Contraseña<span className="text-danger">*</span></Form.Label>
                            <div className="position-relative">
                                <Form.Control
                                    required
                                    onChange={this.handleChange}
                                    value={this.state.user.password}
                                    className="pr-4"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    name="password"
                                />

                                <div style={{ top: -2, right: 0, cursor: 'pointer' }} onClick={() => this.setState(prev => ({ showPassword: !prev.showPassword }))} className="position-absolute pointer-event  translate-middle-y p-2">
                                    {this.state.showPassword ? (
                                        <img src={eyeClose} className="pointer-event " width={24} alt="search" />
                                    ) : (
                                        <img src={eyeOpen} className="pointer-event " width={24} alt="search" />
                                    )}
                                </div>
                            </div>
                            <small style={{ opacity: 0.7 }} className="ml-1">La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial</small>
                        </div>
                        <div className="mt-2">
                            <Form.Label>Dirección<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={this.handleChange} value={this.state.user.address} type="text" name="address" />
                        </div>
                        <div className="mt-2">
                            <Form.Label>Teléfono<span className="text-danger">*</span></Form.Label>
                            <Form.Control required onChange={this.handleChange} value={this.state.user.phone} type="text" name="phone" />
                        </div>

                        <div className="mt-5">
                            <h3>Sensores</h3>

                            <div className="position-relative sensor-form d-flex">
                                <Form.Control
                                    onKeyPress={this.handleKeyPress}
                                    onChange={(e) => this.setState({ sensorSearch: e.target.value })}
                                    value={this.state.sensorSearch}
                                    type="text"
                                    placeholder="Buscar sensor"
                                    className="pl-5"
                                />

                                <div style={{ top: -2 }} className="position-absolute translate-middle-y p-2 pl-3">
                                    <img src={searchIcon} width={19} alt="search" />
                                </div>

                                <Button className="ml-2 px-4" type="button" onClick={this.handleSensorSearch}>Buscar</Button>
                            </div>

                            {this.state.user.sensors.length > 0 && (
                                <div className="d-flex flex-column my-3">
                                    <p style={{ marginBottom: '8px' }}>Seleccionados: </p>
                                    <div className="sensors-selected-container">
                                        {this.state.user.sensors.map(sensor => (
                                            <p key={sensor} className="sensor-selected">
                                                {sensor}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            )}

                            <div className="sensors-container">
                                {this.state.sensors.map((sensor, index) => {
                                    const isChecked = this.state.user.sensors.includes(sensor.id)
                                    return (
                                        (
                                            <div key={index} className="card-sensor">
                                                <Form.Check type="checkbox" checked={isChecked} onChange={this.handleSensorSelected} id={sensor.id} label={sensor.id} />
                                            </div>
                                        )
                                    )
                                })}
                            </div>

                            <Pagination className="d-flex justify-content-center">
                                <Pagination.Prev onClick={() => this.handlePageChange(this.state.currentPage - 1)} />
                                {Array.from({ length: this.state.totalPages }, (_, i) => (
                                    <Pagination.Item key={i} active={i + 1 === this.state.currentPage} onClick={() => this.handlePageChange(i + 1)}>
                                        {i + 1}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next onClick={() => this.handlePageChange(this.state.currentPage + 1)} />
                            </Pagination>
                        </div>

                        <div className="mt-5 w-100 justify-content-center align-items-center d-flex">
                            <Button disabled={isButtonDisabled} type="submit" className="px-4 py-2">Agregar</Button>
                            <Link to="/users" className="btn px-4 py-2 btn-danger ml-2">Cancelar</Link>
                        </div>
                    </Form>

                </section>
            </>
        )
    }
}

export default UsersEditView